@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
    background-color: #FCC71D; 
    color: #fff; 
    /* padding: 1rem; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
  }

  .navbar .nav-link:hover {
    color: #ffffff;
  }

  .navbar button:hover {
    background-color: #ea3f0a;
  }

  .navbar button {
    background-color: #FF5733;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    color: #fff;
    font-weight: bold;
  }


/* .custom-scrollbar::-webkit-scrollbar {
    width: 0.5px;  
} */

/* .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #656262;  
    border-radius: 0px;     
} */

/* .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #888; 
} */

.custom-scrollbar {
    scrollbar-width:thin;   
    scrollbar-color: #888f95 #f7f4f4; 
    /* 1D5C96 logo color */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}
  
/* FOR PRODUCT NAME  */

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; 
  line-height: 1.5em;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* Custom scrollbar styling */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888f95 #f7f4f4; 
  /* 1D5C96 logo color */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.pdf-container {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.pdf-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.spinner {
  --clr: rgb(7, 7, 62);
  --gap: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

.spinner span {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--clr);
  opacity: 0;
}

.spinner span:nth-child(1) {
  animation: fade 1s ease-in-out infinite;
}

.spinner span:nth-child(2) {
  animation: fade 1s ease-in-out 0.33s infinite;
}

.spinner span:nth-child(3) {
  animation: fade 1s ease-in-out 0.66s infinite;
}

@keyframes fade {
  0%, 100% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}

.bg-blue-100 {
  background-color: rgba(37, 99, 235, 0.1); 
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #78CFEC66; /* Match the header background color */
  z-index: 10; /* Ensure the header stays above the table rows */
}

.table-container {
  height: 500px; /* Adjust as needed */
  overflow: auto;
}
