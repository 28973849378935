.autocompleteWrapper {
  @apply flex w-full relative;
}
 
.autocompleteWrapper>div:nth-child(1) {
  width: 100%;
}
 
.autocompleteWrapper input {
  /* padding: 0 10px; */
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  width: 97%;
  height: 45px;
  border-width: 0px;
 
}
 
 
@media(max-width: 480px) {
  .autocompleteWrapper input {
    border-radius: 5px;
  border-width: 0px;
  }
}
 
.autocompleteWrapper input:focus {
  box-shadow: none;
  outline: none;
}
 
