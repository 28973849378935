
.range-picker-placeholder::placeholder {
  color: black;
}

.css-dev-only-do-not-override-11xg00t .ant-input-outlined:hover {
  background-color: #ffffff;
}

.ant-input-outlined:hover {
  border-color: #ccc !important;
  background-color: #ffffff;
}

.ant-input-outlined:focus {
  border-color: #ccc !important;
  box-shadow: none !important;
  outline: 0;
  background-color: #ffffff;
}

.ant-input[disabled] {
  color: #575e66; /* Adjust the color as needed */
}

:where(.css-dev-only-do-not-override-11xg00t).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-11xg00t).ant-input-outlined:focus-within {
  border-color: #ccc !important;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
  background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-select-outlined:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #ccc !important; /* Set your desired border color */

  background-color: #ffffff;
}

.ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow
  .ant-select-selector {
  border-color: #ccc !important; /* Set your desired border color */
}

.ant-select.ant-select-outlined.ant-select-single.ant-select-show-arrow
  .ant-select-selector:hover,
.ant-select-selector:focus {
  border-color: #ccc !important; /* Set the same border color for hover and focus states */
  box-shadow: none; /* Optional: Remove any box shadow on hover/focus */
}

.ant-select-selector:hover {
  border-color: #ccc !important; /* Set the same border color for hover and focus states */
  box-shadow: none;
}

.ant-select-focused .ant-select-selector {
  box-shadow: none !important; /* Remove the box shadow on focus */
  /* border-color:none !important; */
  border-color: #ccc !important;
  /* Set the desired border color */
}


/* Tooltip styles */
.tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #ffffff;
  color: #ccc;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.875rem;
  margin-top: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}


.autocompletee input {
  width: 100%;
}

.ant-picker-outlined:focus-within,
.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within,
.ant-picker-outlined:hover {
  border-color: #ccc !important;
  box-shadow: none !important;
}

/* remove spinner from input */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #1b4597 !important;
  border-color: #1b4597 !important;}

  .input:focus-within
  .input:focus{
    border-color: #ccc !important;
  box-shadow: none !important;

  }



  /* Add these styles to your globals.css or another appropriate CSS file */
.thin-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.fixed-row-height {
  height: 40px; /* Set to desired row height */
  max-height: 40px;
  overflow: hidden;
}

.ant-select-selector {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  align-items: center; /* Center items vertically */
  max-height: 40px; /* Set a max height for the selected items */
  overflow: hidden; /* Hide overflow */
}



